import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/esnext.typed-array.find-last.js";
import "core-js/modules/esnext.typed-array.find-last-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import { queryDnaTestResults } from "@debionetwork/polkadot-provider";
import { queryLabById } from "@debionetwork/polkadot-provider";
import { generalDebounce } from "@/common/lib/utils";
import { downloadFile, decryptFile, downloadDocumentFile, getIpfsMetaData } from "@/common/lib/pinata-proxy";
import { queryOrderDetailByOrderID, queryServiceById } from "@debionetwork/polkadot-provider";
import { u8aToHex } from "@polkadot/util";
import { submitRatingOrder, getRatingByOrderId } from "@/common/lib/api";
import { downloadIcon, debioIcon, creditCardIcon, starIcon, checkCircleIcon } from "@debionetwork/ui-icons";
import errorMessage from "@/common/constants/error-messages";
import DownloadingDialog from "@/common/components/Dialog/DownloadingDialog.vue";
export default {
  name: "TestResult",
  components: {
    DownloadingDialog
  },
  data: () => ({
    downloadIcon,
    debioIcon,
    creditCardIcon,
    starIcon,
    checkCircleIcon,
    errorMessage,
    privateKey: "",
    publicKey: "",
    idOrder: "",
    ownerAddress: "",
    testResult: {},
    services: [],
    rating: 0,
    review: "",
    ratingTitle: "",
    message: "Please wait",
    ratingSubTitle: "",
    ratingTestResult: null,
    lab: null,
    order: null,
    serviceName: "",
    result: null,
    serviceCategory: "",
    resultLoading: false,
    showModal: false,
    showModalRating: false,
    files: [],
    chunks: 0,
    totalChunks: 0
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData
    }),
    reportResult() {
      if (this.dialog) {
        return "";
      }
      if (this.resultLoading) {
        return "Decrypting report..";
      }
      return this.result ? this.result : "No report available for this result";
    },
    modalTitle() {
      return "Thank you! ".concat("\n", " Your feedback has been sent");
    }
  },
  watch: {
    mnemonicData(val) {
      if (val) this.initialData();
    }
  },
  async created() {
    this.resultLoading = true;
    this.idOrder = this.$route.params.id;
    this.ownerAddress = this.wallet.address;
    if (this.mnemonicData) await this.initialData();
  },
  methods: {
    async initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.privateKey = u8aToHex(cred.boxKeyPair.secretKey);
      if (cred) await this.prepareData();
    },
    async prepareData() {
      await this.getRatingTestResult();
      await this.getTestResult();
      await this.getLabServices();
      await this.getFileUploaded();
      await this.parseResult();
    },
    async getRatingTestResult() {
      try {
        const data = await getRatingByOrderId(this.idOrder);
        this.ratingTestResult = data === null || data === void 0 ? void 0 : data.rating;
        this.ratingTitle = "Rating ".concat(this.ratingTestResult, ",0");
        this.ratingSubTitle = data === null || data === void 0 ? void 0 : data.review;
      } catch (error) {}
    },
    async getTestResult() {
      try {
        this.order = await queryOrderDetailByOrderID(this.api, this.idOrder);
        this.ownerAddress = this.order.customerEthAddress;
        this.testResult = await queryDnaTestResults(this.api, this.order.dnaSampleTrackingId);
      } catch (error) {
        this.resultLoading = false;
      }
    },
    async getLabServices() {
      try {
        this.lab = await queryLabById(this.api, this.testResult.labId);
        this.services = await queryServiceById(this.api, this.order.serviceId);
        this.publicKey = this.lab.info.boxPublicKey;
        this.serviceCategory = this.services.info.category;
        this.serviceName = this.services.info.name;
      } catch (error) {
        this.resultLoading = false;
        this.services = [];
      }
    },
    async getFileUploaded() {
      try {
        if (this.testResult.reportLink !== "") {
          this.files.push({
            fileType: "report",
            fileName: this.serviceName + " Report",
            fileLink: this.testResult.reportLink,
            fileTitle: "Download Report",
            fileSubTitle: "Download Your Test Report"
          });
        }
        if (this.testResult.resultLink !== "") {
          this.files.push({
            fileType: "result",
            fileName: this.serviceName + " Result",
            fileLink: this.testResult.resultLink,
            fileTitle: "Download Raw Data",
            fileSubTitle: "Download Your Genomic Data"
          });
        }
      } catch (error) {
        this.resultLoading = false;
      }
    },
    async parseResult() {
      try {
        const path = this.files[0].fileLink;
        const pair = {
          secretKey: this.privateKey,
          publicKey: this.publicKey
        };
        let fileChunks = [];
        let fileType;
        let name;
        if (/^\[/.test(path)) {
          const links = JSON.parse(path);
          this.totalChunks = links.length;
          for (let i = 0; i < links.length; i++) {
            const {
              rows
            } = await getIpfsMetaData(links[i].split("/").pop());
            const {
              type,
              data
            } = await downloadFile(links[i], true);
            const decryptedFile = decryptFile([data], pair, type);
            fileChunks = fileChunks.concat(decryptedFile);
            fileType = type;
            if (i === 0) {
              name = rows;
            }
            this.chunks += 1;
          }
          await downloadDocumentFile(fileChunks, name[0].metadata.name, fileType, true);
          this.chunks = 0;
        } else {
          const {
            type,
            data
          } = await downloadFile(path, true);
          const decryptedFile = decryptFile([data], pair, type);
          fileChunks = [...fileChunks, ...(decryptedFile ? decryptedFile : [])];
          const unit8arrays = new Uint8Array(fileChunks);
          await downloadDocumentFile(unit8arrays, name[0].metadata.name, fileType);
        }
        this.resultLoading = false;
      } catch (error) {
        this.resultLoading = false;
      }
    },
    actionDownload: generalDebounce(async function (link) {
      this.resultLoading = true;
      try {
        const pair = {
          secretKey: this.privateKey,
          publicKey: this.publicKey
        };
        let fileChunks = [];
        let name;
        let fileType;
        if (/^\[/.test(link)) {
          const links = JSON.parse(link);
          for (let i = 0; i < links.length; i++) {
            const {
              rows
            } = await getIpfsMetaData(links[i].split("/").pop());
            const {
              type,
              data
            } = await downloadFile(links[i], true);
            const decryptedFile = decryptFile([data], pair, type);
            fileType = type;
            fileChunks = fileChunks.concat(decryptedFile);
            if (i === 0) {
              name = rows;
            }
            this.chunks += 1;
          }
          await downloadDocumentFile(fileChunks, name[0].metadata.name, fileType, true);
          this.chunks = 0;
        } else {
          const {
            rows
          } = await getIpfsMetaData(link.split("/").pop());
          const {
            type,
            data
          } = await downloadFile(link, true);
          const decryptedFile = decryptFile([data], pair, type);
          fileChunks = [...fileChunks, ...(decryptedFile ? decryptedFile : [])];
          const unit8arrays = new Uint8Array(fileChunks);
          this.resultLoading = false;
          await downloadDocumentFile(unit8arrays, rows[0].metadata.name, type);
        }
      } catch (error) {} finally {
        this.resultLoading = false;
      }
    }, 500),
    actionRating() {
      this.showModalRating = true;
    },
    closeModal() {
      this.$emit("showModal", false);
      this.showModal = false;
    },
    getRating(stars) {
      this.rating = stars;
    },
    async submitRating() {
      try {
        const data = await submitRatingOrder(this.testResult.labId, this.order.serviceId, this.testResult.orderId, this.order.customerId, this.rating, this.review);
        this.ratingTestResult = data.rating;
        this.ratingTitle = "Rating ".concat(this.ratingTestResult, ",0");
        this.ratingSubTitle = data.review;
        this.showModalRating = false;
        this.showModal = true;
      } catch (error) {
        this.showModalRating = false;
        this.showModal = true;
      }
    }
  },
  rules: {
    document: {
      review: [val => !!val || errorMessage.REQUIRED]
    }
  }
};